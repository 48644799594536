<template>
    <base-sticky-heading>
        <template #left>
            <div class="flex gap-2.5">
                <!-- Date -->
                <form-date-dropdown
                    @set-dates="setDates"
                    @set-date-range-key="setDateRangeKey"
                    :date-range-key="dateRangeKey"
                    :query-key="queryKey"
                    :query-value="queryValue"
                    :is-single-filter="isSingleFilter"
                    :report-type="reportType"
                />

                <!-- Filter -->
                <base-button
                    variant="default"
                    icon="line-icons:general:settings-01"
                    :label="$t('reports.header.filterReport')"
                    @click="onShowFilterModal"
                />
            </div>
        </template>

        <template #right>
            <div class="flex gap-2.5">
                <!-- Schedule -->
                <base-button
                    variant="primary"
                    icon="line-icons:time:clock"
                    @click="onOpenScheduleModal"
                >
                    {{ $t('reports.header.scheduleReport') }}
                </base-button>

                <!-- Print -->
                <base-button
                    class="p-2.5"
                    variant="default"
                    icon="line-icons:media-&-devices:printer"
                    @click="$emit('print')"
                    :hasPadding="false"
                />

                <!-- Export -->
                <base-dropdown variant="default" position="right">
                    <template #btn-content>
                        {{ $t('reports.header.export.label') }}

                        <base-icon
                            name="line-icons:arrows:chevron-down"
                            variant="inherit"
                        />
                    </template>

                    <template #default="{ toggleDropdown }">
                        <base-dropdown-item
                            v-for="option in exportOptions"
                            :key="option"
                            @click="
                                () => {
                                    onExport(option.name)
                                    toggleDropdown()
                                }
                            "
                        >
                            {{ option.label }}
                        </base-dropdown-item>
                    </template>
                </base-dropdown>
            </div>
        </template>

        <slot />
    </base-sticky-heading>

    <!-- Filter modal -->
    <reports-filter-modal
        :show="showFilterModal"
        :filter-account-type="filterAccountType"
        @modal-close="showFilterModal = false"
        @filter="onFilter"
    />

    <!-- Schedule modal -->
    <reports-schedule-modal
        v-if="showScheduleModal"
        :show="showScheduleModal"
        :report-name="reportName"
        :report-type="reportType"
        @modal-close="showScheduleModal = false"
        @schedule="onSchedule"
    />

    <reports-export-loading-modal
        v-if="modalExportLoading"
        :show="modalExportLoading"
        :loading="modalExportLoading"
        @cancel="onCancelExport"
        @modal-close="onCancelExport"
    />
</template>

<script setup>
import { REPORT_TYPES } from '@tenant/modules/tenant/reports/utils/constants'

const { t } = useI18n()
const emit = defineEmits([
    'set-dates',
    'set-date-range-key',
    'print',
    'export',
    'cancel-export',
])
const startDate = ref(null)
const endDate = ref(null)
const { execute: executeSaveConfig } = useApi('/api/_info/config-me', 'POST')
const { execute: executeGetConfig } = useApi('/api/_info/config-me', 'GET')
const keepModalLoading = ref(false)

const props = defineProps({
    reportName: {
        type: String,
        default: '',
    },
    dateRangeKey: {
        type: String,
        default: 'year',
    },
    queryKey: {
        type: String,
        required: true,
    },
    queryValue: {
        type: String,
        required: true,
    },
    reportType: {
        type: String,
        default: '',
        validator(value) {
            return Object.values(REPORT_TYPES).includes(value)
        },
    },
    isSingleFilter: {
        type: Boolean,
        default: false,
    },
    loadingExport: {
        type: Boolean,
        default: false,
    },
})

const router = useRouter()
const route = useRoute()
const filterAccountType = ref('all')

const setDateRangeKey = (key) => {
    emit('set-date-range-key', key)
}

const formatDate = (date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

const setDates = (start, end) => {
    startDate.value = formatDate(start)
    endDate.value = formatDate(end)

    emit('set-dates', start, end)
}

// Filter
const showFilterModal = ref(false)

const onShowFilterModal = () => {
    showFilterModal.value = true
}

const onFilter = async (value) => {
    await executeSaveConfig({
        data: {
            payload: [
                {
                    key: props.reportType + '.filter-account-type',
                    value: {
                        _value: value,
                    },
                },
            ],
        },
    })

    filterAccountType.value = value
    showFilterModal.value = false
    await router.replace({
        query: {
            ...route.query,
            filter_account_type: value,
        },
    })

    emit('filter-account-type', value)
}

// Export
const exportOptions = ref([
    {
        label: t('reports.header.export.optionPDF'),
        name: 'pdf',
    },
    {
        label: t('reports.header.export.optionXLSX'),
        name: 'xlsx',
    },
    {
        label: t('reports.header.export.optionXLS'),
        name: 'xls',
    },
    {
        label: t('reports.header.export.optionCSV'),
        name: 'csv',
    },
])

const onExport = async (value) => {
    emit('export', value)
}

// Schedule
const showScheduleModal = ref(false)

const onOpenScheduleModal = () => {
    showScheduleModal.value = true
}

const onSchedule = () => {
    showScheduleModal.value = false
}

onMounted(() => {
    ;(async () => {
        const data = await executeGetConfig({
            queries: {
                keys: [props.reportType + '.filter-account-type'],
            },
        })

        if (data) {
            filterAccountType.value =
                data[props.reportType + '.filter-account-type']?._value
        }
    })()
})

const onCancelExport = () => {
    keepModalLoading.value = false
    emit('cancel-export')
}

const modalExportLoading = computed(
    () => props.loadingExport || keepModalLoading.value
)

watch(
    () => props.loadingExport,
    (value) => {
        if (value) {
            keepModalLoading.value = true
            setTimeout(() => {
                keepModalLoading.value = false
            }, 2500)
        }
    },
    { immediate: true }
)
</script>
