export default {
    reports: {
        general: {
            itemTotalLabel: 'Total for {name}',
            currencyNote: '**Amount is displayed in your base currency',
        },

        sidebar: {
            label: 'Reports',
            profitAndLossLabel: 'Profit & Loss',
            balanceSheetLabel: 'Balance Sheet',
            accountTransactionsLabel: 'Account Transactions',
            cashflowStatementLabel: 'Cashflow Statement',
            ledger: 'General Ledger',
            detailLedger: 'Detail General Ledger',
            trialBalance: 'Trial Balance',
        },

        header: {
            filterReport: 'Filter report',
            scheduleReport: 'Schedule Report',

            export: {
                label: 'Export As',
                optionPDF: 'PDF',
                optionXLSX: 'XLSX (Microsoft Excel)',
                optionXLS: 'XLS (Microsoft Excel 1997-2004 Compatible)',
                optionCSV: 'CSV (Comma Separated Value)',
            },
        },

        table: {
            accountLabel: 'Account',
            totalLabel: 'Total',
            note: '**Amount is displayed in your base currency',
        },

        filterModal: {
            title: 'Filter Accounts',
            description: 'Accounts with transactions',
            filterOption1Title: 'Accounts without zero balance',
            filterOption1Description:
                'Filter the accounts except the ones without zero-balance.',
            filterOption2Title: 'All Accounts ',
            filterOption2Description:
                'Filter all accounts, including the ones with zero-balance.',
            filterOption3Title: 'Accounts with transactions',
            filterOption3Description:
                'Filter accounts with transactions that were created during the specified period.',
            filter: 'Filter now',
        },
        downloadingModal: {
            title: 'Download Report',
            description: 'please wait ...',
        },
        scheduleModal: {
            title: 'Schedule Report',
            description: 'Report Name: {name}',
            frequency: {
                label: 'Frequency',
                optionDaily: 'Daily',
                optionWeekly: 'Weekly',
                optionMonthly: 'Monthly',
                optionQuarterly: 'Quarterly',
                hintText:
                    'Report will be generated and sent on a {frequency} basis.',
            },
            startDateTimeLabel: 'Start Date & Time',
            hoursHintText: 'Hours',
            minutesHintText: 'Minutes',
            detailsTitle: 'Bill Details',
            emailRecipientsLabel: 'Email Recipients',
            attachReport: {
                label: 'Attach Report as',
                optionPDF: 'PDF',
                optionXLS: 'XLS (Microsoft Excel 1997-2004 Compatible)',
                optionXLXS: 'XLSX (Microsoft Excel)',
                optionCSV: 'CSV',
            },
            status: {
                label: 'Status',
                optionActive:
                    'The report is active and will be sent according to your settings.',
                optionInactive: 'The report is <b>not</b> active.',
            },
        },

        profitAndLoss: {
            operating_income: 'Operating Income',
            total_operating_income: 'Total Operating Income',
            cost_of_goods_sold: 'Cost of Goods Sold',
            total_cost_of_goods_sold: 'Total Cost of Goods Sold',
            gross_profit: 'Gross Profit',
            operating_expense: 'Operating Expense',
            total_operating_expense: 'Total Operating Expense',
            operating_profit: 'Operating Profit',
            net_earnings: 'Net Earnings',
            income: 'Income',
            other_income: 'Other income(Loss)',
            expenses: 'Expenses',
            other_expenses: 'Other Expenses',
            cost_of_sales: 'Cost of Sales',
            net_profit_loss: 'Net Profit/Loss',
            title: 'Profit & Loss',
            description:
                'Generate your profit and loss based on your transactions in Cybooks.',
            downloadPDF: 'Download PDF',
            reportStartDate: 'Start date',
            reportEndDate: 'End date',
            totalLabel: 'Total',
            totalSalesHeadline: 'Sales',
            totalSalesLabel: 'Total for Operating Income',
            totalCostOfSalesHeadline: 'Cost of Sales',
            totalCostOfSalesLabel: 'Total Cost of Sales',
            totalExpensesHeadline: 'Expenses',
            totalExpensesLabel: 'Total Expenses',
            profitLabel: 'Profit',

            table: {
                itemTotalLabel: 'Total for {name}',
                itemProfit: 'Gross Profit',
                netProfit: 'Net Profit/Loss',
            },
        },

        balanceSheet: {
            title: 'Balance Sheet',
            table: {
                assets: 'Assets',
                cash: 'Cash',
                bank: 'Bank',
                otherCurrentAssets: 'Other Current Assets',
                otherAssets: 'Other Assets',
                fixedAssets: 'Fixed Assets',
                liabilitiesAndEquities: 'Liabilities & Equities',
                currentLiabilities: 'Current Liabilities',
                otherLiabilities: 'Other Liabilities',
                equities: 'Equities',
                accountsReceivable: 'Accounts Receivable',
                currentAssets: 'Current Assets',
                longTermAssets: 'Long-term Assets',
                accountsPayable: 'Accounts Payable',
                nonCurrentLiabilities: 'Non-current Liabilities',
                shareholdersEquity: "Shareholders' Equity",
            },
        },

        cashflowStatement: {
            title: 'Cashflow Statement',
            profitForTheYear: 'Profit for the year',
            adjustments: 'Adjustments for {name}',
            totalAdjustments: 'Total Adjustments for {name}',
            operatingProfit: 'non-cash income and expenses:',
            cashFlowOperating: 'Cash Flow from Operating Activities',
            netCashOperating: 'Net Cash provided by Operating Activities',
            cashFlowInvesting: 'Cash Flow from Investing Activities',
            netCashInvesting: 'Net Cash provided by Investing Activities',
            cashFlowFinancing: 'Cash Flow from Financing Activities',
            netCashFinancing: 'Net Cash provided by Financing Activities',
            cashFlowCash: 'Cash and cash equivalents at beginning of year',
            netCashCash: 'Total Cash and cash equivalents at beginning of year',
            netIncreaseInCash: 'Net increase in cash and cash equivalents',
            beginningCashBalance:
                'Cash and cash equivalents at beginning of period',
            endingCashBalance: 'Cash and cash equivalents at end of period',
        },

        accountTransactions: {
            title: 'Account Transactions',
            sub_title: 'Basis: Accrual',
            as_on: 'As on {from}',
            total_debit_credit: 'Total Debits and Credits ({from} - {end})',
            table: {
                date: 'DATE',
                account: 'ACCOUNT',
                transactionDetails: 'TRANSACTION DETAILS',
                transactionType: 'TRANSACTION TYPE',
                transaction: 'TRANSACTION',
                reference: 'REFERENCE',
                debit: 'DEBIT',
                credit: 'CREDIT',
                amount: 'AMOUNT',
            },
        },

        trialBalance: {
            title: 'Trial Balance',
            table: {
                asset: 'Assets',
                equity: 'Equity',
                expense: 'Expenses',
                income: 'Income',
                liability: 'Liabilities',
                total: 'Total',
                credit: 'NET Credit',
                debit: 'NET Debit',
                balance: 'Balance',
            },
        },

        ledger: {
            title: 'General Ledger',
            table: {
                date: 'Date',
                account: 'Account',
                credit: 'Credit',
                debit: 'Debit',
                balance: 'Balance',
            },
            note: '**Amount is displayed in your base currency',
        },
        detailLedger: {
            title: 'Detail General Ledger',
            openingBalance: 'Opening Balance',
            closingBalance: 'Closing Balance',
            table: {
                date: 'Date',
                transactionType: 'Transaction Type',
                description: 'Description',
                reference: 'Reference',
                credit: 'Credit',
                debit: 'Debit',
                runningBalance: 'Running Balance',
                foreignCurrency: 'Deb./Cred. (FC) (currency)',
                exchangedAmount: 'Deb./Cred. (FC)',
                tax: 'Tax',
                taxRate: 'Tax Rate',
                taxName: 'Tax Name',
            },
            totalAccount: 'Total {account}',
            netMovement: 'Net movement',
            total: 'Total',
            note: '**Amount is displayed in your base currency',
        },
    },
}
