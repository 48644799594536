<template>
    <div
        class="flex flex-col justify-between gap-4 py-2 text-sm"
        :class="{ 'border-b': hasTotal }"
    >
        <!-- Name -->
        <div class="font-bold text-gray-700">
            <slot name="itemNameLabel">
                {{ name }}
            </slot>
        </div>

        <!-- Children -->
        <template v-for="child in children" :key="child">
            <slot name="child" v-bind="{ childrenClick, child }">
                <div
                    class="flex justify-between gap-4 pl-10 pr-7 font-semibold text-primary-700 hover:cursor-pointer hover:text-primary-500 hover:underline"
                    @click="childrenClick(child.id)"
                >
                    <slot name="itemLabel" v-bind="{ item: child }">
                        <span>{{ child.name }}</span>
                    </slot>
                    <span>{{ $filters.numberFormat(child.value ?? 0) }}</span>
                </div>
            </slot>
        </template>

        <slot></slot>

        <slot name="footer" v-bind="{ hasTotal, total, name }">
            <!-- Total -->
            <div
                v-if="hasTotal"
                class="flex justify-between gap-4 pr-7 text-gray-700"
            >
                <div class="font-semibold">
                    <slot name="itemTotalLabel">
                        {{ name }}
                    </slot>
                </div>
                <div>{{ $filters.numberFormat(total ?? 0) }}</div>
            </div>
        </slot>
    </div>
</template>

<script setup>
const emit = defineEmits(['children-click'])
defineProps({
    name: {
        type: String,
    },

    children: {
        type: Array,
    },

    total: {
        type: Number,
    },

    hasTotal: {
        type: Boolean,
        default: true,
    },
})

const childrenClick = (id) => {
    emit('children-click', id)
}
</script>
