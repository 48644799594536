<template>
    <reports-header
        :report-name="$t('reports.trialBalance.title')"
        :report-type="REPORT_TYPES.TRIAL_BALANCE"
        @set-dates="setReportDates"
        @set-date-range-key="setDateRangeKey"
        @export="onExport"
        :loading-export="loadingExport"
        :date-range-key="dateRangeKey"
        query-key="date"
        query-value="filter.date"
        :is-single-filter="true"
        @print="printContent('trial-balance')"
        @cancel-export="cancelExport"
    >
        <div id="trial-balance" class="flex flex-1 flex-col">
            <reports-header-section
                :name="company?.company_name"
                :title="$t('reports.trialBalance.title')"
                :end-date="endDate"
            />

            <div v-if="loadingSearch" class="relative -mt-16 flex-1">
                <div
                    class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
                >
                    <span
                        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                    >
                        <base-loading size="lg" />
                    </span>
                </div>
            </div>

            <!-- Data table -->
            <div v-else class="mx-auto w-full max-w-4xl">
                <data-grid
                    class="flex justify-between gap-4 border-b border-t px-7 py-2 text-xs text-gray-500"
                    custom-classes="border-none border-b-none border-t rounded-none px-0 flex flex-col"
                    :data-source="dataSource"
                    :columns="columns"
                    :loading="loadingSearch"
                    :border-table-header="true"
                >
                    <template #column-account="{ item }">
                        <router-link
                            class="font-semibold text-primary-600"
                            v-if="item.chart_of_account"
                            :to="{
                                name: 'reports.account-transactions',
                                query: {
                                    range_key: getDateRangeKey(),
                                    chart_of_account_id:
                                        item.chart_of_account.id,
                                },
                            }"
                            >{{ item.account }}</router-link
                        >

                        <span v-else class="font-bold">
                            {{
                                $t(`reports.trialBalance.table.${item.account}`)
                            }}
                        </span>
                    </template>

                    <template #column-debit="{ item }">
                        {{
                            item.debit ? $filters.numberFormat(item.debit) : ''
                        }}
                    </template>

                    <template #column-credit="{ item }">
                        {{
                            item.credit
                                ? $filters.numberFormat(item.credit)
                                : ''
                        }}
                    </template>
                </data-grid>

                <p
                    v-if="dataSource"
                    class="py-10 text-left text-sm font-normal text-gray-700"
                >
                    {{ $t('reports.general.currencyNote') }}

                    <base-badge :label="CURRENCIES.eur.iso" variant="gray" />
                </p>
            </div>
        </div>
    </reports-header>
</template>

<script setup>
import { isEmpty } from 'lodash-es'
import { CURRENCIES } from '@tenant/utils/constants'
import { printContent } from '@tenant/utils/helper'
import { useReport } from '@tenant/modules/tenant/reports/composables/use-report'
import { REPORT_TYPES } from '@tenant/modules/tenant/reports/utils/constants'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const {
    endDate,
    setReportDates,
    dateRangeKey,
    setDateRangeKey,
    exportReportTypeWithDocumentType,
    recreateCancelToken,
    loading: loadingExport,
    cancelTokenSource,
} = useReport(REPORT_TYPES.TRIAL_BALANCE)
const { entities: balance, useApiSearch } = useApiFactory('trial-balance')
const { loading: loadingSearch, execute } = useApiSearch(
    {
        ...route.query,
    },
    true,
    true
)
const { company } = useAuth()

useHead({ title: t('reports.trialBalance.title') })

const getDateRangeKey = () => {
    const key = localStorage.getItem('reports.trialBalance.dateRangeKey')
    return key ? key : 'year'
}

watch(
    () => route?.query,
    () => {
        if (
            route?.query?.filter?.['date'] &&
            route?.query?.filter?.['date'].length > 1
        ) {
            router.replace({
                query: {
                    ...route?.query,
                    to_date: route.query.filter['date'][1],
                },
            })

            return execute({
                ...route?.query,
                to_date: route.query.filter['date'][1],
            })
        }

        return execute({
            ...route?.query,
        })
    },
    { immediate: !isEmpty(route?.query.filter), flush: 'post' }
)

// Data grid
const columns = [
    {
        property: 'account',
        label: t('reports.ledger.table.account'),
        dataCellClass: '!text-gray-700 font-medium !py-2',
        headerCellClass: 'text-left bg-transparent font-normal',
    },
    {
        property: 'debit',
        label: t('reports.trialBalance.table.debit'),
        dataCellClass: '!text-primary-600 font-semibold !py-2',
        headerCellClass: 'text-right bg-transparent font-normal',
        end: true,
    },
    {
        property: 'credit',
        label: t('reports.trialBalance.table.credit'),
        dataCellClass: '!text-primary-600 font-semibold !py-2',
        headerCellClass: 'text-right bg-transparent font-normal',
        end: true,
    },
]

const dataSource = computed(() => {
    let data = []
    for (const item of Object.entries(balance.value)) {
        const [key, value] = item

        if (!Array.isArray(value) || value.length === 0) {
            continue
        }

        data.push({
            account: key,
        })

        data = [
            ...data,
            ...value.map((item) => {
                return {
                    account: item.chart_of_account.name,
                    debit: item.debit,
                    credit: item.credit,
                    chart_of_account: item.chart_of_account,
                }
            }),
        ]
    }

    data.push({
        account: 'total',
        debit: balance.value.total_debit,
        credit: balance.value.total_credit,
    })

    return data
})

const onExport = async (type) => {
    await exportReportTypeWithDocumentType(
        type,
        REPORT_TYPES.TRIAL_BALANCE,
        route.query
    )
}

const cancelExport = () => {
    cancelTokenSource.cancel()
    recreateCancelToken()
}
</script>
