import { delay } from '@tenant/utils/helper'

export const useReport = (reportType) => {
    const {
        execute: executeGenerateFile,
        cancelTokenSource,
        result: file,
        replaceEndpoint,
        recreateCancelToken,
        loading,
    } = useApi('/api/{report_types}/export-file/{type}', 'GET')

    const exportFile = (fileName, content) => {
        const linkSource = `data:application/pdf;base64,${content}`
        const downloadLink = document.createElement('a')
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
    }

    const objectToQueryString = (obj, parentKey = null) => {
        const queryString = Object.keys(obj).map((key) => {
            const value = obj[key]

            if (parentKey) {
                key = `${parentKey}[${key}]`
            }

            if (value && typeof value === 'object') {
                return objectToQueryString(value, key)
            }

            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        })

        return queryString.join('&')
    }

    /**
     *
     * @param {string} documentType
     * @param {string} reportType
     * @param {object} query
     * @returns {Promise<void>}
     */
    const exportReportTypeWithDocumentType = async (
        documentType,
        reportType,
        query
    ) => {
        const startTime = Date.now()
        const queryString = objectToQueryString(query)
        replaceEndpoint(`/api/{report_types}/export-file/{type}?${queryString}`)

        const request = executeGenerateFile({
            params: {
                type: documentType,
                report_types: reportType.replace('-', '_'),
            },
        })

        await Promise.all([request, delay(2500)])
        const elapsedTime = Date.now() - startTime

        // Check if the request took less than 2.5 seconds
        if (elapsedTime < 2500) {
            // Wait for the remaining time
            await delay(2500 - elapsedTime)
        }

        exportFile(reportType + '.' + documentType, file.value.content)
    }

    const startDate = ref(new Date())
    const endDate = ref(new Date())

    const getDateRangeKey = () => {
        const key = localStorage.getItem(`reports.${reportType}.dateRangeKey`)
        return key ? key : 'year'
    }

    const setDateRangeKey = (key) => {
        localStorage.setItem(`reports.${reportType}.dateRangeKey`, key)
    }

    const setReportDates = (start, end) => {
        startDate.value = start
        endDate.value = end
        localStorage.setItem(
            `reports.${reportType}.dateRangeValue`,
            JSON.stringify({ start: formatDate(start), end: formatDate(end) })
        )
    }

    const formatDate = (date) => {
        return `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
    }

    const dateRangeKey = ref(getDateRangeKey())

    return {
        exportReportTypeWithDocumentType,
        recreateCancelToken,
        loading,
        cancelTokenSource,
        startDate,
        endDate,
        setReportDates,
        dateRangeKey,
        setDateRangeKey,
        getDateRangeKey,
    }
}
