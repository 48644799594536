<template>
    <reports-header
        :report-name="$t('reports.accountTransactions.title')"
        @set-dates="setReportDates"
        @set-date-range-key="setDateRangeKey"
        @export="onExport"
        :date-range-key="dateRangeKey"
        :report-type="REPORT_TYPES.ACCOUNT_TRANSACTIONS"
        :loading-export="loadingExport"
        query-key="account_transaction_date"
        query-value="filter.journalEntry.date"
        @print="
            printContent('account-transactions', {
                orientation: 'landscape',
            })
        "
        @cancel-export="cancelExport"
    >
        <div id="account-transactions" class="flex flex-1 flex-col">
            <reports-header-section
                :name="company?.company_name"
                :chart-of-account="result?.chart_of_account"
                :title="$t('reports.accountTransactions.title')"
                :sub-title="
                    result?.chart_of_account
                        ? $t('reports.accountTransactions.sub_title')
                        : null
                "
                :startDate="startDate"
                :endDate="endDate"
            />

            <div v-if="loadingSearch" class="relative -mt-16 flex-1">
                <div
                    class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
                >
                    <span
                        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                    >
                        <base-loading size="lg" />
                    </span>
                </div>
            </div>

            <!-- Data table -->
            <data-grid
                v-else
                is-sticky-header
                class="!rounded-none border-l-0 border-r-0 border-t-0"
                :data-source="accountTransactions.data"
                :columns="columns"
                :loading="loadingSearch"
            >
                <template v-if="result?.chart_of_account" #additional-top-row>
                    <data-grid-row
                        class="border-t border-gray-200 bg-white hover:bg-gray-50"
                    >
                        <data-grid-cell
                            class="px-6 py-4 text-left text-sm font-normal !text-gray-700 text-gray-500"
                        >
                            {{
                                $t('reports.accountTransactions.as_on', {
                                    from: $filters.dateCustomFormat(
                                        startDate,
                                        'DD MMM YYYY'
                                    ),
                                })
                            }}
                        </data-grid-cell>

                        <data-grid-cell
                            class="px-6 py-4 text-left text-sm font-normal !text-gray-700 text-gray-500"
                            colspan="6"
                        >
                            {{
                                $t(
                                    'contacts.detail.statement.file.summaryInfo.openingBalance'
                                )
                            }}
                        </data-grid-cell>

                        <data-grid-cell
                            class="px-6 py-4 text-left text-sm font-normal !text-gray-700 text-gray-500"
                            colspan="2"
                        >
                            {{
                                $filters.symbolCurrency(
                                    result.opening,
                                    CURRENCIES.eur
                                )
                            }}
                        </data-grid-cell>
                    </data-grid-row>
                </template>

                <template
                    v-if="result?.chart_of_account"
                    #additional-bottom-row
                >
                    <data-grid-row
                        class="border-t border-gray-200 bg-white hover:bg-gray-50"
                    >
                        <data-grid-cell />

                        <data-grid-cell
                            class="px-6 py-4 text-left text-sm font-normal text-gray-700"
                            colspan="5"
                        >
                            {{
                                $t(
                                    'reports.accountTransactions.total_debit_credit',
                                    {
                                        from: $filters.dateCustomFormat(
                                            startDate,
                                            'DD MMM YYYY'
                                        ),
                                        end: $filters.dateCustomFormat(
                                            endDate,
                                            'DD MMM YYYY'
                                        ),
                                    }
                                )
                            }}
                        </data-grid-cell>

                        <data-grid-cell
                            class="px-6 py-4 text-left text-sm font-normal text-gray-700"
                        >
                            {{
                                $filters.symbolCurrency(
                                    result.debit,
                                    CURRENCIES.eur
                                )
                            }}
                        </data-grid-cell>

                        <data-grid-cell
                            class="px-6 py-4 text-left text-sm font-normal text-gray-700"
                            colspan="2"
                        >
                            {{
                                $filters.symbolCurrency(
                                    result.credit,
                                    CURRENCIES.eur
                                )
                            }}
                        </data-grid-cell>
                    </data-grid-row>

                    <data-grid-row
                        class="border-t border-gray-200 bg-white hover:bg-gray-50"
                    >
                        <data-grid-cell
                            class="px-6 py-4 text-left text-sm font-normal text-gray-700"
                        >
                            {{
                                $t('reports.accountTransactions.as_on', {
                                    from: $filters.dateCustomFormat(
                                        endDate,
                                        'DD MMM YYYY'
                                    ),
                                })
                            }}
                        </data-grid-cell>

                        <data-grid-cell
                            class="px-6 py-4 text-left text-sm font-normal text-gray-700"
                            colspan="5"
                        >
                            {{ $t('bookeepingAccounts.detail.closingBalance') }}
                        </data-grid-cell>

                        <data-grid-cell
                            class="px-6 py-4 text-left text-sm font-normal text-gray-700"
                            colspan="3"
                        >
                            {{
                                $filters.symbolCurrency(
                                    result.closing,
                                    CURRENCIES.eur
                                )
                            }}
                        </data-grid-cell>
                    </data-grid-row>
                </template>

                <template #column-date="{ item }">
                    {{
                        $filters.dateCustomFormat(
                            item.journal_entry.date,
                            'DD MMM YYYY'
                        )
                    }}
                </template>

                <template #column-account="{ item }">
                    {{ item.chart_of_account.name }}
                </template>

                <template #column-transactionDetails="{ item }">
                    {{ item.journal_entry.transaction_details }}
                </template>

                <template #column-transactionType="{ item }">
                    {{ item.journal_entry.transaction_type }}
                </template>

                <template #column-transaction="{ item }">
                    {{ item.journal_entry.transaction }}
                </template>

                <template #column-reference="{ item }">
                    {{ item.journal_entry.reference }}
                </template>

                <template #column-debit="{ item }">
                    {{ item.debit ? $filters.numberFormat(item.debit) : '' }}
                </template>

                <template #column-credit="{ item }">
                    {{ item.credit ? $filters.numberFormat(item.credit) : '' }}
                </template>

                <template #column-amount="{ item }">
                    {{ $filters.numberFormat(item.amount) }}
                </template>

                <template #footer v-if="!result?.chart_of_account">
                    <grid-pagination
                        :total="accountTransactions.last_page"
                        :page="accountTransactions.current_page"
                    />
                </template>
            </data-grid>
        </div>
    </reports-header>
</template>

<script setup>
import { isEmpty } from 'lodash-es'
import { CURRENCIES } from '@tenant/utils/constants'
import { printContent } from '@tenant/utils/helper'
import { useReport } from '@tenant/modules/tenant/reports/composables/use-report'
import { REPORT_TYPES } from '@tenant/modules/tenant/reports/utils/constants'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const {
    startDate,
    endDate,
    setReportDates,
    dateRangeKey,
    setDateRangeKey,
    exportReportTypeWithDocumentType,
    recreateCancelToken,
    loading: loadingExport,
    cancelTokenSource,
} = useReport(REPORT_TYPES.ACCOUNT_TRANSACTIONS)

const { entities: accountTransactions, useApiSearch } = useApiFactory(
    'journal-lines/paginate'
)
const { loading: loadingSearch, execute, result } = useApiSearch({}, true, true)
const { company } = useAuth()

useHead({ title: t('reports.accountTransactions.title') })

watch(
    () => route?.query?.range_key,
    (value) => {
        if (!value) return

        setDateRangeKey(value)
        dateRangeKey.value = value
    },
    { immediate: true }
)

watch(
    () => route?.query,
    () => {
        if (
            route?.query?.filter?.['journalEntry.date'] &&
            route?.query?.filter?.['journalEntry.date'].length > 1 &&
            route?.query?.filter?.['journalEntry.date'][1]?.length > 1
        ) {
            router.replace({
                query: {
                    ...route?.query,
                    from_date: route.query.filter['journalEntry.date'][1][0],
                    to_date: route.query.filter['journalEntry.date'][1][1],
                },
            })

            return execute({
                ...route?.query,
                from_date: route.query.filter['journalEntry.date'][1][0],
                to_date: route.query.filter['journalEntry.date'][1][1],
            })
        }

        return execute({
            ...route?.query,
        })
    },
    { immediate: !isEmpty(route?.query.filter), flush: 'post' }
)
// Data grid
const columns = [
    {
        property: 'date',
        label: t('reports.accountTransactions.table.date'),
        headerCellClass: '!rounded-none',
        dataCellClass: '!text-gray-700 w-40',
    },
    {
        property: 'account',
        label: t('reports.accountTransactions.table.account'),
        dataCellClass: '!text-gray-700',
    },
    {
        property: 'transactionDetails',
        label: t('reports.accountTransactions.table.transactionDetails'),
        dataCellClass: '!text-gray-700',
    },
    {
        property: 'transactionType',
        label: t('reports.accountTransactions.table.transactionType'),
        dataCellClass: '!text-gray-700',
    },
    {
        property: 'transaction',
        label: t('reports.accountTransactions.table.transaction'),
        dataCellClass: '!text-gray-700',
    },
    {
        property: 'reference',
        label: t('reports.accountTransactions.table.reference'),
        dataCellClass: '!text-gray-700',
    },
    {
        property: 'debit',
        label: t('reports.accountTransactions.table.debit'),
        dataCellClass: '!text-primary-600 font-semibold',
    },
    {
        property: 'credit',
        label: t('reports.accountTransactions.table.credit'),
        dataCellClass: '!text-primary-600 font-semibold',
    },
    {
        property: 'amount',
        label: t('reports.accountTransactions.table.amount'),
        headerCellClass: '!rounded-none',
        dataCellClass: '!text-primary-600 font-semibold',
    },
]

const onExport = async (type) => {
    await exportReportTypeWithDocumentType(
        type,
        'account_transactions',
        route.query
    )
}

const cancelExport = () => {
    cancelTokenSource.cancel()
    recreateCancelToken()
}
</script>
