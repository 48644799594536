<template>
    <div class="flex flex-col gap-2.5 px-5 pb-16 pt-12 text-center">
        <h5 class="text-2xl font-normal">{{ name }}</h5>
        <p class="font-bold">{{ title }}</p>
        <p v-if="subTitle">
            {{ subTitle }}
        </p>
        <p v-if="chartOfAccount?.name" class="font-bold">
            {{ chartOfAccount.name }}
        </p>
        <p>
            {{ description }}
        </p>
    </div>
</template>

<script setup>
import { dateCustomFormat } from '@tenant/core/filter'

const props = defineProps({
    name: {
        type: String,
        default: '',
    },

    chartOfAccount: {
        type: Object,
        default: () => ({}),
    },

    title: {
        type: String,
        default: '',
    },

    subTitle: {
        type: String,
        required: false,
    },

    startDate: {
        type: [Date, String],
        default: '',
    },

    endDate: {
        type: [Date, String],
        default: '',
    },
})

const { t } = useI18n()
const description = computed(() => {
    const { startDate, endDate } = props
    if (!startDate && !endDate) return ''

    if (!startDate && endDate)
        return t('filters.asOfDate', {
            date: dateCustomFormat(endDate, 'DD MMM YYYY'),
        })

    return (
        t('filters.from') +
        ' ' +
        dateCustomFormat(startDate, 'DD MMM YYYY') +
        ' ' +
        t('filters.to') +
        ' ' +
        dateCustomFormat(endDate, 'DD MMM YYYY')
    )
})
</script>
