<template>
    <base-modal close :show="show" size="xl" @modal-close="closeModal">
        <template #modal-header>
            <h4 class="mb-1 text-lg font-medium text-gray-900">
                {{ $t('reports.filterModal.title') }}
            </h4>

            <p class="text-sm text-gray-700">
                {{ $t('reports.filterModal.description') }}
            </p>
        </template>

        <div class="-mx-6 flex flex-col gap-3 border-b border-t p-6">
            <template v-for="filter in filterOptions" :key="filter">
                <form-radio-group-item
                    v-model="filterSelectedOption"
                    name="filterOption"
                    :value="filter.value"
                    bullet-position="left"
                    bullet-icon=""
                >
                    <template #default="{ checked }">
                        <div class="text-sm">
                            <p
                                class="font-medium"
                                :class="
                                    checked
                                        ? 'text-primary-800'
                                        : 'text-gray-700'
                                "
                            >
                                {{ filter.title }}
                            </p>
                            <p
                                class=""
                                :class="
                                    checked
                                        ? 'text-primary-600'
                                        : 'text-gray-500'
                                "
                            >
                                {{ filter.description }}
                            </p>
                        </div>
                    </template>
                </form-radio-group-item>
            </template>
        </div>

        <template #modal-footer>
            <div class="flex justify-end gap-3 pt-1">
                <base-button variant="default" @click="closeModal">
                    {{ $t('general.cancel') }}
                </base-button>

                <base-button @click="onFilter">
                    {{ $t('reports.filterModal.filter') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const { t } = useI18n()
const emit = defineEmits(['modal-close', 'filter'])

const props = defineProps({
    show: {
        type: Boolean,
    },
    filterAccountType: {
        type: String,
        default: 'all',
    },
})

const filterSelectedOption = ref('all')

watch(
    () => props.filterAccountType,
    (value) => {
        filterSelectedOption.value = value
    }
)

const filterOptions = ref([
    {
        value: 'zero-balance',
        title: t('reports.filterModal.filterOption1Title'),
        description: t('reports.filterModal.filterOption1Description'),
    },
    {
        value: 'all',
        title: t('reports.filterModal.filterOption2Title'),
        description: t('reports.filterModal.filterOption2Description'),
    },
    {
        value: 'with-transactions',
        title: t('reports.filterModal.filterOption3Title'),
        description: t('reports.filterModal.filterOption3Description'),
    },
])

const closeModal = () => {
    emit('modal-close')
}

const onFilter = () => {
    emit('filter', filterSelectedOption.value)
}
</script>
