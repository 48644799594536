<template>
    <base-modal close :show="show" size="md" @modal-close="closeModal">
        <template #modal-header>
            <h4 class="mb-1 text-lg font-medium text-gray-900">
                {{ $t('reports.downloadingModal.title') }}
            </h4>
        </template>

        <hr class="-mx-6 mb-5 border-t-[1px] border-gray-200" />
        <div class="relative h-[100px]">
            <div v-if="loading" class="relative z-50 h-full w-full bg-white">
                <span
                    class="absolute left-1/2 top-0 -translate-x-1/2 transform"
                >
                    <base-loading size="lg" />
                </span>
                <span
                    class="absolute bottom-0 left-1/2 -translate-x-1/2 transform text-lg font-medium text-gray-700"
                >
                    {{ $t('reports.downloadingModal.description') }}
                </span>
            </div>
        </div>

        <template #modal-footer>
            <div class="mt-3 flex justify-between">
                <base-button
                    variant="default"
                    @click="cancerExport"
                    class="flex w-full items-center justify-between text-center"
                >
                    <template #content>
                        <div class="w-full">
                            {{ $t('general.cancel') }}
                        </div>
                    </template>
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['cancel', 'modal-close'])

defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})

const closeModal = () => {
    emit('modal-close')
}

const cancerExport = () => {
    emit('modal-close')
    emit('cancel')
}
</script>
