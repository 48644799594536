<template>
    <reports-header
        :report-name="$t('reports.profitAndLoss.title')"
        @set-dates="setReportDates"
        @set-date-range-key="setDateRangeKey"
        @export="onExport"
        :report-type="REPORT_TYPES.PROFIT_AND_LOSS"
        :date-range-key="dateRangeKey"
        :loading-export="loadingExport"
        query-key="profit_lost_date"
        query-value="filter.generalLedgers.date"
        @print="printContent('report-content')"
        @cancel-export="cancelExport"
    >
        <div id="report-content" class="flex flex-1 flex-col">
            <!-- Report header -->
            <reports-header-section
                :name="company?.company_name"
                :title="$t('reports.profitAndLoss.title')"
                :startDate="startDate"
                :endDate="endDate"
            />

            <div v-if="loadingSearch" class="relative -mt-16 flex-1">
                <div
                    class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
                >
                    <span
                        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                    >
                        <base-loading size="lg" />
                    </span>
                </div>
            </div>

            <!-- Data table -->
            <reports-table v-else>
                <!-- Item -->
                <template v-for="group in hydrateData" :key="group">
                    <reports-table-item
                        v-for="item in group.items"
                        :key="item"
                        :name="item.name"
                        :total="item.total"
                        :children="item.children"
                        @children-click="onChildrenClick"
                    >
                        <template #itemNameLabel>
                            <span class="pl-7">{{ item.name }}</span>
                        </template>

                        <template #itemTotalLabel>
                            <span class="pl-7">
                                {{
                                    $t(
                                        'reports.profitAndLoss.table.itemTotalLabel',
                                        {
                                            name: item.name,
                                        }
                                    )
                                }}
                            </span>
                        </template>
                    </reports-table-item>

                    <!-- Profit -->
                    <div
                        class="flex justify-end gap-24 border-b px-7 py-2 text-sm text-gray-700"
                    >
                        <div class="font-semibold">
                            {{ group.name }}
                        </div>
                        <div>
                            {{ $filters.numberFormat(group.profit ?? 0) }}
                        </div>
                    </div>
                </template>
            </reports-table>
        </div>
    </reports-header>
</template>

<script setup>
import { watch } from 'vue'
import { isEmpty } from 'lodash-es'
import { useReport } from '@tenant/modules/tenant/reports/composables/use-report'

import { REPORT_TYPES } from '@tenant/modules/tenant/reports/utils/constants'
import { printContent } from '@tenant/utils/helper'

const { t } = useI18n()
const route = useRoute()
const {
    startDate,
    endDate,
    getDateRangeKey,
    setReportDates,
    dateRangeKey,
    setDateRangeKey,
    exportReportTypeWithDocumentType,
    recreateCancelToken,
    loading: loadingExport,
    cancelTokenSource,
} = useReport(REPORT_TYPES.PROFIT_AND_LOSS)
const { entities: profitsAndLoss, useApiSearch } =
    useApiFactory('profit-and-loss')
const { execute, loading: loadingSearch } = useApiSearch(
    {
        ...route.query,
    },
    true,
    true
)

const { company } = useAuth()
const router = useRouter()
useHead({ title: t('reports.profitAndLoss.title') })

const onChildrenClick = (id) => {
    router.push({
        name: 'reports.account-transactions',
        query: {
            range_key: getDateRangeKey(),
            chart_of_account_id: id,
        },
    })
}

watch(
    () => route?.query,
    () => {
        if (
            route?.query?.filter?.['generalLedgers.date'] &&
            route?.query?.filter?.['generalLedgers.date'].length > 1 &&
            route?.query?.filter?.['generalLedgers.date'][1]?.length > 1
        ) {
            router.replace({
                query: {
                    ...route?.query,
                    from_date: route.query.filter['generalLedgers.date'][1][0],
                    to_date: route.query.filter['generalLedgers.date'][1][1],
                },
            })

            return execute({
                ...route?.query,
                from_date: route.query.filter['generalLedgers.date'][1][0],
                to_date: route.query.filter['generalLedgers.date'][1][1],
            })
        }

        return execute({
            ...route?.query,
        })
    },
    { immediate: !isEmpty(route?.query.filter), flush: 'post' }
)

const hydrateData = computed(() => {
    return [
        {
            name: t('reports.profitAndLoss.gross_profit'),
            profit: profitsAndLoss.value?.gross_profit,
            items: [
                {
                    name: t('reports.profitAndLoss.income'),
                    total: profitsAndLoss.value?.income?.total_income,
                    children: mapChildren(profitsAndLoss.value?.income?.items),
                },
                {
                    name: t('reports.profitAndLoss.cost_of_sales'),
                    total: profitsAndLoss.value?.cost_of_sales
                        ?.total_cost_of_sales,
                    children: mapChildren(
                        profitsAndLoss.value?.cost_of_sales?.items
                    ),
                },
            ],
        },
        {
            name: t('reports.profitAndLoss.net_earnings'),
            profit: profitsAndLoss.value?.net_earnings,
            items: [
                {
                    name: t('reports.profitAndLoss.other_income'),
                    total: profitsAndLoss.value?.other_incomes
                        ?.total_other_incomes,
                    children: mapChildren(
                        profitsAndLoss.value?.other_incomes?.items
                    ),
                },
                {
                    name: t('reports.profitAndLoss.expenses'),
                    total: profitsAndLoss.value?.expenses?.total_expenses,
                    children: mapChildren(
                        profitsAndLoss.value?.expenses?.items
                    ),
                },
                {
                    name: t('reports.profitAndLoss.other_expenses'),
                    total: profitsAndLoss.value?.other_expenses
                        ?.total_other_expenses,
                    children: mapChildren(
                        profitsAndLoss.value?.other_expenses?.items
                    ),
                },
            ],
        },
    ]
})

const onExport = async (type) => {
    await exportReportTypeWithDocumentType(
        type,
        REPORT_TYPES.PROFIT_AND_LOSS,
        route.query
    )
}

const mapChildren = (data) => {
    return data?.map((item) => ({
        id: item.id,
        name: item.name,
        value: item.total,
    }))
}

const cancelExport = () => {
    cancelTokenSource.cancel()
    recreateCancelToken()
}
</script>
