const navigation = [
    {
        id: 'sas-reports',
        path: 'reports',
        label: 'reports.sidebar.label',
        icon: 'line-icons:charts:bar-chart-08',
        position: 60,
        permission: 'view_reports',
    },
    {
        id: 'sas-reports-profit-and-loss',
        path: 'reports.profit-and-loss',
        label: 'reports.sidebar.profitAndLossLabel',
        parent: 'sas-reports',
        position: 1,
    },
    {
        id: 'sas-reports-balance-sheet',
        path: 'reports.balance-sheet',
        label: 'reports.sidebar.balanceSheetLabel',
        parent: 'sas-reports',
        position: 2,
    },
    {
        id: 'sas-reports-cashflow-statement',
        path: 'reports.cashflow-statement',
        label: 'reports.sidebar.cashflowStatementLabel',
        parent: 'sas-reports',
        position: 3,
    },
    {
        id: 'sas-reports-account-transactions',
        path: 'reports.account-transactions',
        label: 'reports.sidebar.accountTransactionsLabel',
        parent: 'sas-reports',
        position: 4,
    },
    {
        id: 'sas-reports-ledger',
        path: 'reports.ledger',
        label: 'reports.sidebar.ledger',
        parent: 'sas-reports',
        position: 5,
    },
    {
        id: 'sas-reports-detail-ledger',
        path: 'reports.detail-ledger',
        label: 'reports.sidebar.detailLedger',
        parent: 'sas-reports',
        position: 6,
    },
    {
        id: 'sas-trial-balance',
        path: 'reports.trial-balance',
        label: 'reports.sidebar.trialBalance',
        parent: 'sas-reports',
        position: 7,
    },
]

export default navigation
