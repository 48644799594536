<template>
    <div>
        <template v-for="(item, index) in group.items" :key="item">
            <reports-table-item
                :name="item.name"
                :total="item.total"
                :has-total="!isNil(item.total)"
                :children="item.children"
                @children-click="onChildrenClick"
                class="!gap-2"
            >
                <template #itemNameLabel>
                    <div
                        class="flex justify-between pl-7"
                        :style="{
                            marginLeft: `${props.level * 20}px`,
                        }"
                    >
                        <div
                            class="flex cursor-pointer items-center gap-1 text-sm font-normal text-gray-700"
                            @click="onCollapse(index)"
                        >
                            <div class="w-4">
                                <base-icon
                                    v-if="
                                        (item.items && item.items.length > 0) ||
                                        (item.children &&
                                            item.children.length > 0)
                                    "
                                    :name="
                                        groupCollapse.includes(index)
                                            ? 'line-icons:arrows:chevron-up'
                                            : 'line-icons:arrows:chevron-down'
                                    "
                                    variant="inherit"
                                />
                            </div>
                            {{ item.name }}
                        </div>

                        <span v-if="!isNil(item.value) && item.value !== 0">
                            {{ $filters.numberFormat(item.value) }}
                        </span>
                    </div>
                </template>

                <template v-if="item?.items && !groupCollapse.includes(index)">
                    <reports-table-layout
                        :group="item"
                        @children-click="onChildrenClick"
                        :level="level + 1"
                    />
                </template>

                <template #child="{ childrenClick, child }">
                    <div>
                        <div
                            v-if="!groupCollapse.includes(index)"
                            class="flex cursor-pointer justify-between gap-4 py-2 pl-10 text-sm font-normal text-gray-700 hover:text-primary-500"
                            @click="childrenClick(child.id)"
                        >
                            <span
                                class="pl-2"
                                :style="{
                                    marginLeft: `${(props.level + 1) * 20}px`,
                                }"
                            >
                                {{ child.name }}
                            </span>
                            <span>
                                {{ $filters.numberFormat(child.value ?? 0) }}
                            </span>
                        </div>
                    </div>
                </template>

                <template #footer="{ hasTotal, total }">
                    <div v-if="hasTotal">
                        <div
                            v-if="!groupCollapse.includes(index)"
                            class="flex justify-between gap-4 pb-2 text-sm font-medium text-gray-900"
                        >
                            <div
                                class="pl-7"
                                :style="{
                                    marginLeft: `${(props.level + 2) * 20}px`,
                                }"
                            >
                                {{
                                    $t('reports.general.itemTotalLabel', {
                                        name: item.name,
                                    })
                                }}
                            </div>

                            <div>{{ $filters.numberFormat(total ?? 0) }}</div>
                        </div>
                    </div>
                </template>
            </reports-table-item>
        </template>
    </div>
</template>
<script setup>
import { isNil } from 'lodash-es'

const props = defineProps({
    group: {
        type: Object,
    },
    level: {
        type: Number,
        default: 1,
    },
})

const groupCollapse = ref([])

const emit = defineEmits(['children-click'])

const onChildrenClick = (id) => {
    emit('children-click', id)
}

const onCollapse = (groupIndex) => {
    if (!groupCollapse.value.includes(groupIndex)) {
        groupCollapse.value.push(groupIndex)
    } else {
        groupCollapse.value.splice(groupCollapse.value.indexOf(groupIndex), 1)
    }
}
</script>
