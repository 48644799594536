<template>
    <reports-header
        :report-name="$t('reports.balanceSheet.title')"
        @set-dates="setReportDates"
        @set-date-range-key="setDateRangeKey"
        @export="onExport"
        :report-type="REPORT_TYPES.BALANCE_SHEET"
        :loading-export="loadingExport"
        :date-range-key="dateRangeKey"
        query-key="balance_sheet_date"
        query-value="filter.generalLedgers.date"
        :is-single-filter="true"
        @print="printContent('report-content')"
        @cancel-export="cancelExport"
    >
        <div id="report-content" class="flex flex-1 flex-col">
            <!-- Report header -->
            <reports-header-section
                :name="company?.company_name"
                :title="$t('reports.balanceSheet.title')"
                :endDate="endDate"
            />

            <div v-if="loadingSearch" class="relative -mt-16 flex-1">
                <div
                    class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
                >
                    <span
                        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                    >
                        <base-loading size="lg" />
                    </span>
                </div>
            </div>

            <!-- Data table -->
            <reports-table v-else>
                <!-- Item -->
                <template v-for="(group, index) in hydrateData" :key="group">
                    <div
                        class="flex cursor-pointer items-center gap-1 px-7 py-2 text-sm font-normal text-gray-700"
                        @click="onCollapse(index)"
                    >
                        <div class="w-4">
                            <base-icon
                                :name="
                                    groupCollapse.includes(index)
                                        ? 'line-icons:arrows:chevron-up'
                                        : 'line-icons:arrows:chevron-down'
                                "
                                variant="inherit"
                            />
                        </div>

                        {{ group.name }}
                    </div>

                    <template v-if="!groupCollapse.includes(index)">
                        <!-- LAYOUT -->
                        <reports-table-layout
                            :group="group"
                            @children-click="onChildrenClick"
                            class="pl-4"
                        />

                        <!-- TEST -->
                        <div
                            class="flex justify-between gap-24 border-b border-gray-700 py-4 pl-11 text-sm font-semibold text-gray-700"
                        >
                            <div>
                                {{
                                    $t('reports.general.itemTotalLabel', {
                                        name: group.name,
                                    })
                                }}
                            </div>
                            <div>
                                {{ $filters.numberFormat(group.total ?? 0) }}
                            </div>
                        </div>
                    </template>
                </template>
            </reports-table>
        </div>
    </reports-header>
</template>

<script setup>
import { watch } from 'vue'
import { isEmpty } from 'lodash-es'
import { REPORT_TYPES } from '@tenant/modules/tenant/reports/utils/constants'
import { useReport } from '@tenant/modules/tenant/reports/composables/use-report'
import { printContent } from '@tenant/utils/helper'
const {
    endDate,
    getDateRangeKey,
    setReportDates,
    dateRangeKey,
    setDateRangeKey,
    exportReportTypeWithDocumentType,
    recreateCancelToken,
    loading: loadingExport,
    cancelTokenSource,
} = useReport(REPORT_TYPES.BALANCE_SHEET)
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const { entities: balances, useApiSearch } = useApiFactory('balance-sheet')
const { execute, loading: loadingSearch } = useApiSearch(
    {
        ...route.query,
    },
    true,
    true
)
const { company } = useAuth()

useHead({ title: t('reports.balanceSheet.title') })

// Dates
const groupCollapse = ref([])

const onChildrenClick = (id) => {
    if (!id) {
        router.push({
            name: 'reports.profit-and-loss',
        })

        return
    }

    router.push({
        name: 'reports.account-transactions',
        query: {
            range_key: getDateRangeKey(),
            chart_of_account_id: id,
        },
    })
}

watch(
    () => route?.query,
    () => {
        if (
            route?.query?.filter?.['generalLedgers.date'] &&
            route?.query?.filter?.['generalLedgers.date'].length > 1
        ) {
            router.replace({
                query: {
                    ...route?.query,
                    to_date: route.query.filter['generalLedgers.date'][1],
                },
            })

            return execute({
                ...route?.query,
                to_date: route.query.filter['generalLedgers.date'][1],
            })
        }

        return execute({
            ...route?.query,
        })
    },
    { immediate: !isEmpty(route?.query.filter), flush: 'post' }
)

const hydrateData = computed(() => {
    const data = balances.value
    return [
        {
            name: t('reports.balanceSheet.table.assets'),
            total: data?.asset?.total_asset ?? 0,
            items: [
                {
                    name: t('reports.balanceSheet.table.currentAssets'),
                    total: data?.asset?.current_asset?.total_current_assets,
                    items: [
                        {
                            name: t(
                                'reports.balanceSheet.table.accountsReceivable'
                            ),
                            total: data?.asset?.current_asset
                                ?.account_receivable?.total_account_receivables,
                            children: mapChildren(
                                data?.asset?.current_asset?.account_receivable
                                    ?.items
                            ),
                        },
                        ...mapChildren(data?.asset?.current_asset?.items ?? []),
                    ],
                },
                {
                    name: t('reports.balanceSheet.table.longTermAssets'),
                    total: data?.asset?.long_term_asset?.total_long_term_assets,
                    items: mapChildren(
                        data?.asset?.long_term_asset?.items ?? []
                    ),
                },
            ],
        },
        {
            name: t('reports.balanceSheet.table.liabilitiesAndEquities'),
            total: data?.liabilities?.total_liabilities ?? 0,
            items: [
                {
                    name: t('reports.balanceSheet.table.currentLiabilities'),
                    total: data?.liabilities?.current_liability
                        ?.total_current_liabilities,
                    items: [
                        {
                            name: t(
                                'reports.balanceSheet.table.accountsPayable'
                            ),
                            total: data?.liabilities?.current_liability
                                ?.account_payable?.total_account_payables,
                            children: mapChildren(
                                data?.liabilities?.current_liability
                                    ?.account_payable?.items
                            ),
                        },
                        ...mapChildren(
                            data?.liabilities?.current_liability?.items ?? []
                        ),
                    ],
                },
                {
                    name: t('reports.balanceSheet.table.nonCurrentLiabilities'),
                    total: data?.liabilities?.non_current_liability
                        ?.total_non_current_liabilities,
                    items: mapChildren(
                        data?.liabilities?.non_current_liability?.items ?? []
                    ),
                },
                {
                    name: t('reports.balanceSheet.table.shareholdersEquity'),
                    total: data?.liabilities?.share_holder_equity
                        ?.total_share_holder_equity,
                    items: mapChildren(
                        data?.liabilities?.share_holder_equity?.items ?? []
                    ),
                },
            ],
        },
    ]
})

const mapChildren = (data) => {
    return data?.map((item) => ({
        id: item.id,
        name: item.name,
        value: item.total,
    }))
}

const onExport = async (type) => {
    await exportReportTypeWithDocumentType(
        type,
        REPORT_TYPES.BALANCE_SHEET,
        route.query
    )
}

const onCollapse = (groupIndex) => {
    if (!groupCollapse.value.includes(groupIndex)) {
        groupCollapse.value.push(groupIndex)
    } else {
        groupCollapse.value.splice(groupCollapse.value.indexOf(groupIndex), 1)
    }
}

const cancelExport = () => {
    cancelTokenSource.cancel()
    recreateCancelToken()
}
</script>
