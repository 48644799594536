<template>
    <reports-header
        :report-name="$t('reports.ledger.title')"
        :report-type="REPORT_TYPES.GENERAL_LEDGER"
        @set-dates="setReportDates"
        @set-date-range-key="setDateRangeKey"
        @export="onExport"
        :date-range-key="dateRangeKey"
        :loading-export="loadingExport"
        query-key="general_ledger_date"
        query-value="filter.date"
        @print="
            printContent('report-content', {
                orientation: 'landscape',
            })
        "
        @cancel-export="cancelExport"
    >
        <div id="report-content" class="flex flex-1 flex-col">
            <reports-header-section
                :name="company?.company_name"
                :title="$t('reports.ledger.title')"
                :startDate="startDate"
                :endDate="endDate"
            />

            <div v-if="loadingSearch" class="relative -mt-16 flex-1">
                <div
                    class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
                >
                    <span
                        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                    >
                        <base-loading size="lg" />
                    </span>
                </div>
            </div>

            <!-- Data table -->
            <div v-else class="mx-auto w-full max-w-4xl">
                <data-grid
                    class="flex justify-between gap-4 border-b border-t px-7 py-2 text-xs text-gray-500"
                    custom-classes="border-none border-b-none border-t rounded-none px-0 flex flex-col"
                    :data-source="ledger.data"
                    :columns="columns"
                    :loading="loadingSearch"
                    :table-borderless="true"
                    :border-table-header="true"
                >
                    <template #column-account="{ item }">
                        {{ item.chart_of_account.name }}
                    </template>

                    <template #column-debit="{ item }">
                        {{ $filters.numberFormat(item.debit ?? 0) }}
                    </template>

                    <template #column-credit="{ item }">
                        {{ $filters.numberFormat(item.credit ?? 0) }}
                    </template>

                    <template #column-balance="{ item }">
                        {{ displayBalance(item.balance) }}
                    </template>
                </data-grid>

                <p
                    v-if="ledger.data.length > 0"
                    class="py-10 text-left text-sm font-normal !text-gray-700 text-gray-500"
                >
                    {{ $t('reports.ledger.note') }}

                    <base-badge :label="CURRENCIES.eur.iso" variant="gray" />
                </p>
            </div>
        </div>
    </reports-header>
</template>

<script setup>
import { isEmpty } from 'lodash-es'
import { CURRENCIES } from '@tenant/utils/constants'
import { REPORT_TYPES } from '@tenant/modules/tenant/reports/utils/constants'
import { useReport } from '@tenant/modules/tenant/reports/composables/use-report'
import { numberFormat, printContent } from '@tenant/utils/helper'

const {
    startDate,
    endDate,
    setReportDates,
    dateRangeKey,
    setDateRangeKey,
    exportReportTypeWithDocumentType,
    recreateCancelToken,
    loading: loadingExport,
    cancelTokenSource,
} = useReport(REPORT_TYPES.GENERAL_LEDGER)
const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const { entities: ledger, useApiSearch } = useApiFactory('general-ledgers')
const { loading: loadingSearch, execute } = useApiSearch(
    {
        ...route.query,
    },
    true,
    true
)
const { company } = useAuth()

useHead({ title: t('reports.ledger.title') })

watch(
    () => route?.query,
    () => {
        if (
            route?.query?.filter?.['date'] &&
            route?.query?.filter?.['date'].length > 1 &&
            route?.query?.filter?.['date'][1]?.length > 1
        ) {
            router.replace({
                query: {
                    ...route?.query,
                    from_date: route.query.filter['date'][1][0],
                    to_date: route.query.filter['date'][1][1],
                },
            })

            return execute({
                ...route?.query,
                from_date: route.query.filter['date'][1][0],
                to_date: route.query.filter['date'][1][1],
            })
        }

        return execute({
            ...route?.query,
        })
    },
    { immediate: !isEmpty(route?.query.filter), flush: 'post' }
)

// Data grid
const columns = [
    {
        property: 'account',
        label: t('reports.ledger.table.account'),
        dataCellClass: '!text-gray-700 font-medium !py-2',
        headerCellClass: 'text-left bg-transparent font-normal',
    },
    {
        property: 'debit',
        label: t('reports.ledger.table.debit'),
        dataCellClass: '!text-primary-600 font-semibold !py-2',
        headerCellClass: 'text-right bg-transparent font-normal',
        end: true,
    },
    {
        property: 'credit',
        label: t('reports.ledger.table.credit'),
        dataCellClass: '!text-primary-600 font-semibold !py-2',
        headerCellClass: 'text-right bg-transparent font-normal',
        end: true,
    },
    {
        property: 'balance',
        label: t('reports.ledger.table.balance'),
        dataCellClass: '!text-primary-600 font-semibold !py-2',
        headerCellClass: 'text-right bg-transparent font-normal',
        end: true,
    },
]

const onExport = async (type) => {
    await exportReportTypeWithDocumentType(
        type,
        REPORT_TYPES.GENERAL_LEDGER,
        route.query
    )
}

const displayBalance = (balance) => {
    return balance < 0
        ? `(${numberFormat(Math.abs(balance))})`
        : numberFormat(balance)
}

const cancelExport = () => {
    cancelTokenSource.cancel()
    recreateCancelToken()
}
</script>
