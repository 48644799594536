<template>
    <reports-header
        :report-name="$t('reports.detailLedger.title')"
        :report-type="REPORT_TYPES.DETAIL_GENERAL_LEDGER"
        @set-dates="setReportDates"
        @set-date-range-key="setDateRangeKey"
        @export="onExport"
        :date-range-key="dateRangeKey"
        :loading-export="loadingExport"
        query-key="detail_general_ledger_date"
        query-value="filter.date"
        @print="
            printContent('report-content', {
                orientation: 'landscape',
            })
        "
        @cancel-export="cancelExport"
    >
        <div id="report-content" class="flex flex-1 flex-col">
            <reports-header-section
                :name="company?.company_name"
                :title="$t('reports.detailLedger.title')"
                :startDate="startDate"
                :endDate="endDate"
            />

            <div v-if="loadingSearch" class="relative -mt-16 flex-1">
                <div
                    class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
                >
                    <span
                        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                    >
                        <base-loading size="lg" />
                    </span>
                </div>
            </div>

            <!-- Data table -->
            <div v-else class="w-full">
                <multi-data-grid
                    class="flex justify-between gap-4 border-b border-t py-2 text-xs text-gray-500"
                    custom-classes="border-none border-b-none border-t rounded-none px-0 flex flex-col"
                    :data-source="ledger.data"
                    :columns="columns"
                    :loading="loadingSearch"
                    :table-borderless="true"
                    :border-table-header="true"
                    is-sticky-header
                    identify-group-data="general_ledgers"
                >
                    <template #additional-top-row="{ record }">
                        <tbody
                            v-if="record.chart_of_account?.show_header"
                            class="cursor-pointer border-t border-gray-200 bg-gray-50 hover:bg-gray-200"
                        >
                            <tr>
                                <td
                                    colspan="12"
                                    class="border-t-1 w-full whitespace-nowrap border-gray-300 px-4 py-4 text-left text-sm font-bold text-gray-700"
                                >
                                    {{ record.chart_of_account.name }}
                                </td>
                            </tr>
                        </tbody>

                        <data-grid-row
                            v-if="record.chart_of_account?.show_header"
                            class="border-t border-gray-200 bg-white hover:bg-gray-50"
                        >
                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="4"
                            >
                                {{ $t('reports.detailLedger.openingBalance') }}
                            </data-grid-cell>

                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                <template
                                    v-if="
                                        record.chart_of_account.opening_debit >
                                        0
                                    "
                                >
                                    {{
                                        $filters.numberFormat(
                                            record.chart_of_account
                                                .opening_debit
                                        )
                                    }}
                                </template>
                                <template v-else> - </template>
                            </data-grid-cell>

                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                <template
                                    v-if="
                                        record.chart_of_account.opening_credit >
                                        0
                                    "
                                >
                                    {{
                                        $filters.numberFormat(
                                            record.chart_of_account
                                                .opening_credit
                                        )
                                    }}
                                </template>
                                <template v-else> - </template>
                            </data-grid-cell>

                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                {{
                                    record.chart_of_account
                                        .opening_running_balance === 0
                                        ? ' - '
                                        : displayBalance(
                                              record.chart_of_account
                                                  .opening_running_balance
                                          )
                                }}
                            </data-grid-cell>

                            <data-grid-cell />
                            <data-grid-cell />

                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                <template
                                    v-if="
                                        record.chart_of_account
                                            .opening_amount_vat > 0
                                    "
                                >
                                    {{
                                        $filters.numberFormat(
                                            record.chart_of_account
                                                .opening_amount_vat
                                        )
                                    }}
                                </template>
                                <template v-else> - </template>
                            </data-grid-cell>
                            <data-grid-cell />
                            <data-grid-cell />
                        </data-grid-row>
                    </template>

                    <template #additional-bottom-row="{ record }">
                        <data-grid-row
                            v-if="record.chart_of_account?.show_footer"
                            class="border-t border-gray-200 bg-white hover:bg-gray-50"
                        >
                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="4"
                            >
                                {{
                                    $t('reports.detailLedger.totalAccount', {
                                        account: record.chart_of_account.name,
                                    })
                                }}
                            </data-grid-cell>

                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                <template
                                    v-if="
                                        record.chart_of_account.total_debit > 0
                                    "
                                >
                                    {{
                                        $filters.numberFormat(
                                            record.chart_of_account.total_debit
                                        )
                                    }}
                                </template>
                                <template v-else> - </template>
                            </data-grid-cell>

                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                <template
                                    v-if="
                                        record.chart_of_account.total_credit > 0
                                    "
                                >
                                    {{
                                        $filters.numberFormat(
                                            record.chart_of_account.total_credit
                                        )
                                    }}
                                </template>
                                <template v-else> - </template>
                            </data-grid-cell>

                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                {{
                                    record.chart_of_account
                                        .total_running_balance === 0
                                        ? ' - '
                                        : displayBalance(
                                              record.chart_of_account
                                                  .total_running_balance
                                          )
                                }}
                            </data-grid-cell>

                            <data-grid-cell />
                            <data-grid-cell />

                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                <template
                                    v-if="
                                        record.chart_of_account
                                            .total_amount_vat > 0
                                    "
                                >
                                    {{
                                        $filters.numberFormat(
                                            record.chart_of_account
                                                .total_amount_vat
                                        )
                                    }}
                                </template>
                                <template v-else> - </template>
                            </data-grid-cell>
                            <data-grid-cell />
                            <data-grid-cell />
                        </data-grid-row>
                        <data-grid-row
                            v-if="record.chart_of_account?.show_footer"
                            class="border-t border-gray-200 bg-white hover:bg-gray-50"
                        >
                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="4"
                            >
                                {{ $t('reports.detailLedger.netMovement') }}
                            </data-grid-cell>

                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                {{
                                    record.chart_of_account.total_net_movement >
                                    0
                                        ? $filters.numberFormat(
                                              record.chart_of_account
                                                  .total_net_movement
                                          )
                                        : ' - '
                                }}
                            </data-grid-cell>

                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                {{
                                    record.chart_of_account.total_net_movement <
                                    0
                                        ? $filters.numberFormat(
                                              Math.abs(
                                                  record.chart_of_account
                                                      .total_net_movement
                                              ),
                                              2
                                          )
                                        : ' - '
                                }}
                            </data-grid-cell>

                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                -
                            </data-grid-cell>

                            <data-grid-cell />
                            <data-grid-cell />

                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                -
                            </data-grid-cell>
                            <data-grid-cell />
                            <data-grid-cell />
                        </data-grid-row>

                        <data-grid-row
                            v-if="record.chart_of_account?.show_footer"
                            class="border-t border-gray-200 bg-white hover:bg-gray-50"
                        >
                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="4"
                            >
                                {{ $t('reports.detailLedger.closingBalance') }}
                            </data-grid-cell>

                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                <template
                                    v-if="
                                        record.chart_of_account.closing_debit >
                                        0
                                    "
                                >
                                    {{
                                        $filters.numberFormat(
                                            record.chart_of_account
                                                .closing_debit,
                                            2
                                        )
                                    }}
                                </template>
                                <template v-else> - </template>
                            </data-grid-cell>

                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                <template
                                    v-if="
                                        record.chart_of_account.closing_credit >
                                        0
                                    "
                                >
                                    {{
                                        $filters.numberFormat(
                                            record.chart_of_account
                                                .closing_credit,
                                            2
                                        )
                                    }}
                                </template>
                                <template v-else> - </template>
                            </data-grid-cell>

                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                {{
                                    record.chart_of_account
                                        .closing_running_balance === 0
                                        ? ' - '
                                        : displayBalance(
                                              record.chart_of_account
                                                  .closing_running_balance
                                          )
                                }}
                            </data-grid-cell>

                            <data-grid-cell />
                            <data-grid-cell />

                            <data-grid-cell
                                class="whitespace-nowrap !py-2 px-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                <template
                                    v-if="
                                        record.chart_of_account
                                            .closing_amount_vat > 0
                                    "
                                >
                                    {{
                                        $filters.numberFormat(
                                            record.chart_of_account
                                                .closing_amount_vat,
                                            2
                                        )
                                    }}
                                </template>
                                <template v-else> - </template>
                            </data-grid-cell>
                            <data-grid-cell />
                            <data-grid-cell />
                        </data-grid-row>
                    </template>

                    <template #column-date="{ item }">
                        {{
                            $filters.dateCustomFormat(
                                item.journal_entry.date,
                                'DD MMM YYYY'
                            )
                        }}
                    </template>

                    <template #column-description="{ item }">
                        {{ item.journal_entry.transaction_details }}
                    </template>

                    <template #column-transactionType="{ item }">
                        {{ item.journal_entry.transaction_type }}
                    </template>

                    <template #column-reference="{ item }">
                        {{ item.journal_entry.reference }}
                    </template>

                    <template #column-debit="{ item }">
                        {{
                            item.debit
                                ? $filters.numberFormat(item.debit)
                                : ' - '
                        }}
                    </template>

                    <template #column-credit="{ item }">
                        {{
                            item.credit
                                ? $filters.numberFormat(item.credit)
                                : ' - '
                        }}
                    </template>

                    <template #column-running_balance="{ item }">
                        {{ displayBalance(item.running_balance) }}
                    </template>

                    <template #column-exchanged_amount="{ item }">
                        {{ displayExchangedAmount(item.exchanged_amount) }}
                    </template>

                    <template #column-tax="{ item }">
                        {{
                            item.amount_vat > 0
                                ? $filters.numberFormat(item.amount_vat)
                                : ' - '
                        }}
                    </template>

                    <template #column-taxRate="{ item }">
                        {{
                            item.tax_rule
                                ? `${item.tax_rule?.tax_rate} %`
                                : ' - '
                        }}
                    </template>

                    <template #column-taxName="{ item }">
                        {{ item.tax_rule ? item.tax_rule.name : ' - ' }}
                    </template>

                    <template #fixed-bottom-row>
                        <data-grid-row
                            v-if="ledger.page === ledger.total"
                            class="border-t border-gray-200 bg-white hover:bg-gray-50"
                        >
                            <data-grid-cell
                                class="whitespace-nowrap !px-4 py-4 text-left text-sm font-semibold text-gray-700"
                                colspan="4"
                            >
                                {{ $t('reports.detailLedger.total') }}
                            </data-grid-cell>

                            <data-grid-cell
                                class="whitespace-nowrap px-4 py-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                {{
                                    totalDebit > 0
                                        ? $filters.numberFormat(totalDebit)
                                        : ' - '
                                }}
                            </data-grid-cell>

                            <data-grid-cell
                                class="whitespace-nowrap px-4 py-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                {{
                                    totalCredit > 0
                                        ? $filters.numberFormat(totalCredit)
                                        : ' - '
                                }}
                            </data-grid-cell>

                            <data-grid-cell
                                class="whitespace-nowrap px-4 py-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                {{
                                    totalRunningBalance === 0
                                        ? ' - '
                                        : displayBalance(totalRunningBalance)
                                }}
                            </data-grid-cell>

                            <data-grid-cell />
                            <data-grid-cell />

                            <data-grid-cell
                                class="whitespace-nowrap px-4 py-4 text-left text-sm font-semibold text-gray-700"
                                colspan="1"
                                :end="true"
                            >
                                {{
                                    totalAmountVat > 0
                                        ? $filters.numberFormat(
                                              totalAmountVat,
                                              2
                                          )
                                        : ' - '
                                }}
                            </data-grid-cell>
                            <data-grid-cell />
                            <data-grid-cell />
                        </data-grid-row>
                    </template>

                    <template #footer v-if="ledger.data.length">
                        <grid-pagination
                            :total="ledger.total"
                            :page="ledger.page"
                        />
                    </template>
                </multi-data-grid>

                <p
                    v-if="ledger.data.length > 0"
                    class="px-7 py-10 text-left text-sm font-normal text-gray-700"
                >
                    {{ $t('reports.detailLedger.note') }}

                    <base-badge :label="CURRENCIES.eur.iso" variant="gray" />
                </p>
            </div>
        </div>
    </reports-header>
</template>

<script setup>
import { isEmpty } from 'lodash-es'
import { CURRENCIES } from '@tenant/utils/constants'
import { REPORT_TYPES } from '@tenant/modules/tenant/reports/utils/constants'
import { useReport } from '@tenant/modules/tenant/reports/composables/use-report'
import { numberFormat, printContent } from '@tenant/utils/helper'
import BigNumber from 'bignumber.js'

const {
    startDate,
    endDate,
    setReportDates,
    dateRangeKey,
    setDateRangeKey,
    exportReportTypeWithDocumentType,
    recreateCancelToken,
    loading: loadingExport,
    cancelTokenSource,
} = useReport(REPORT_TYPES.DETAIL_GENERAL_LEDGER)
const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const { entities: ledger, useApiSearch } = useApiFactory(
    'detail-general-ledgers'
)
const { loading: loadingSearch, execute } = useApiSearch(
    {
        ...route.query,
    },
    true,
    true
)
const { company } = useAuth()

useHead({ title: t('reports.detailLedger.title') })

watch(
    () => route?.query,
    () => {
        if (
            route?.query?.filter?.['date'] &&
            route?.query?.filter?.['date'].length > 1 &&
            route?.query?.filter?.['date'][1]?.length > 1
        ) {
            router.replace({
                query: {
                    ...route?.query,
                    from_date: route.query.filter['date'][1][0],
                    to_date: route.query.filter['date'][1][1],
                },
            })

            return execute({
                ...route?.query,
                from_date: route.query.filter['date'][1][0],
                to_date: route.query.filter['date'][1][1],
            })
        }

        return execute({
            ...route?.query,
        })
    },
    { immediate: !isEmpty(route?.query.filter), flush: 'post' }
)

// Data grid
const columns = [
    {
        property: 'date',
        label: t('reports.detailLedger.table.date'),
        dataCellClass:
            'whitespace-nowrap !text-gray-700 font-medium !py-2 w-1/12',
        headerCellClass: 'text-left bg-transparent !px-4 font-normal',
    },
    {
        property: 'transactionType',
        label: t('reports.detailLedger.table.transactionType'),
        dataCellClass:
            'whitespace-nowrap !text-gray-700 font-medium !py-2 w-1/12',
        headerCellClass: 'text-left bg-transparent font-normal',
    },
    {
        property: 'description',
        label: t('reports.detailLedger.table.description'),
        dataCellClass:
            'whitespace-nowrap !text-gray-700 font-medium !py-2 w-2/12',
        headerCellClass: 'text-left bg-transparent font-normal',
    },
    {
        property: 'reference',
        label: t('reports.detailLedger.table.reference'),
        dataCellClass:
            'whitespace-nowrap !text-gray-700 font-medium !py-2 w-2/12',
        headerCellClass: 'text-left bg-transparent font-normal',
    },
    {
        property: 'debit',
        label: t('reports.detailLedger.table.debit'),
        dataCellClass:
            'whitespace-nowrap !text-primary-600 font-semibold !py-2 w-1/12',
        headerCellClass: 'text-right bg-transparent font-normal',
        end: true,
    },
    {
        property: 'credit',
        label: t('reports.detailLedger.table.credit'),
        dataCellClass:
            'whitespace-nowrap !text-primary-600 font-semibold !py-2 w-1/12',
        headerCellClass: 'text-right bg-transparent font-normal',
        end: true,
    },
    {
        property: 'running_balance',
        label: t('reports.detailLedger.table.runningBalance'),
        dataCellClass:
            'whitespace-nowrap !text-primary-600 font-semibold !py-2 w-1/12',
        headerCellClass: 'text-right bg-transparent font-normal',
        end: true,
    },
    {
        property: 'foreign_currency',
        label: t('reports.detailLedger.table.foreignCurrency'),
        dataCellClass:
            'whitespace-nowrap !text-primary-600 font-semibold !py-2 w-1/12',
        headerCellClass: 'text-right bg-transparent font-normal',
        end: true,
    },
    {
        property: 'exchanged_amount',
        label: t('reports.detailLedger.table.exchangedAmount'),
        dataCellClass:
            'whitespace-nowrap !text-primary-600 font-semibold !py-2 w-1/12',
        headerCellClass: 'text-right bg-transparent font-normal',
        end: true,
    },
    {
        property: 'tax',
        label: t('reports.detailLedger.table.tax'),
        dataCellClass:
            'whitespace-nowrap !text-primary-600 font-semibold !py-2 w-1/12',
        headerCellClass: 'text-right bg-transparent font-normal',
        end: true,
    },
    {
        property: 'taxRate',
        label: t('reports.detailLedger.table.taxRate'),
        dataCellClass:
            'whitespace-nowrap !text-primary-600 font-semibold !py-2 w-1/12',
        headerCellClass: 'text-right bg-transparent font-normal',
        end: true,
    },
    {
        property: 'taxName',
        label: t('reports.detailLedger.table.taxName'),
        dataCellClass:
            'whitespace-nowrap !text-primary-600 font-semibold !py-2 w-3/12',
        headerCellClass: 'text-right bg-transparent font-normal',
        end: true,
    },
]

const totalDebit = computed(() => {
    return new BigNumber(ledger.value.total_debit).toNumber()
})

const totalCredit = computed(() => {
    return new BigNumber(ledger.value.total_credit).toNumber()
})

const totalRunningBalance = computed(() => {
    return new BigNumber(ledger.value.total_running_balance).toNumber()
})

const totalAmountVat = computed(() => {
    return new BigNumber(ledger.value.total_amount_vat).toNumber()
})

const onExport = async (type) => {
    await exportReportTypeWithDocumentType(
        type,
        REPORT_TYPES.DETAIL_GENERAL_LEDGER,
        route.query
    )
}

const displayBalance = (balance) => {
    const formattedNumber = numberFormat(Math.abs(balance))

    return balance < 0 ? `(${formattedNumber})` : formattedNumber
}

const displayExchangedAmount = (exchangedAmount) => {
    return exchangedAmount ? numberFormat(exchangedAmount) : ' - '
}

const cancelExport = () => {
    cancelTokenSource.cancel()
    recreateCancelToken()
}
</script>
