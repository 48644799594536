<template>
    <base-modal
        close
        :show="show"
        size="xl"
        @modal-close="closeModal"
        :loading="loadingGet || loadingSave"
    >
        <template #modal-header>
            <h4 class="mb-1 text-lg font-medium text-gray-900">
                {{ $t('reports.scheduleModal.title') }}
            </h4>

            <p class="text-sm text-gray-700">
                {{
                    $t('reports.scheduleModal.description', {
                        name: reportName,
                    })
                }}
            </p>
        </template>

        <div class="-mx-6 flex flex-col gap-2.5 border-b border-t p-6 pb-1">
            <!-- Frequency -->
            <form-control-group-row class="items-center">
                <form-control-label
                    class="min-w-[146px]"
                    :label="$t('reports.scheduleModal.frequency.label')"
                />
                <div class="w-32">
                    <form-single-select
                        size="sm"
                        :options="frequencyOptions"
                        v-model="reportFrequency"
                    />
                </div>
                <span class="text-sm font-medium text-gray-500">
                    {{
                        $t('reports.scheduleModal.frequency.hintText', {
                            frequency: reportFrequency,
                        })
                    }}
                </span>
            </form-control-group-row>

            <!-- Start date and time -->
            <form-control-group-row>
                <form-control-label
                    class="min-w-[146px]"
                    :label="$t('reports.scheduleModal.startDateTimeLabel')"
                />

                <div>
                    <form-date-picker
                        v-model="reportDate"
                        size="sm"
                        single
                        auto-apply
                    />
                </div>

                <div class="w-28">
                    <form-single-select
                        v-model="reportHours"
                        :options="hoursOptions"
                        size="sm"
                    />
                    <span class="text-xs text-gray-500">
                        {{ $t('reports.scheduleModal.hoursHintText') }}
                    </span>
                </div>

                <div class="w-28">
                    <form-single-select
                        v-model="reportMinutes"
                        :options="minutesOptions"
                        size="sm"
                    />
                    <span class="text-xs text-gray-500">
                        {{ $t('reports.scheduleModal.minutesHintText') }}
                    </span>
                </div>
            </form-control-group-row>

            <h4 class="text-lg font-medium">
                {{ $t('reports.scheduleModal.detailsTitle') }}
            </h4>

            <!-- Email -->
            <form-control-group-row class="items-center">
                <form-control-label
                    class="min-w-[146px]"
                    :label="$t('reports.scheduleModal.emailRecipientsLabel')"
                />

                <form-tags-input
                    v-model="reportRecipients"
                    :test="reportRecipients"
                    size="sm"
                />
            </form-control-group-row>

            <!-- Attach report as -->
            <form-control-group-row>
                <form-control-label
                    class="min-w-[146px]"
                    :label="$t('reports.scheduleModal.attachReport.label')"
                />

                <div class="flex flex-col gap-2.5">
                    <form-radio
                        :text="
                            $t('reports.scheduleModal.attachReport.optionPDF')
                        "
                        value="pdf"
                        v-model="reportAttachAs"
                    />
                    <form-radio
                        :text="
                            $t('reports.scheduleModal.attachReport.optionCSV')
                        "
                        value="csv"
                        v-model="reportAttachAs"
                    />
                    <form-radio
                        :text="
                            $t('reports.scheduleModal.attachReport.optionXLXS')
                        "
                        value="xlsx"
                        v-model="reportAttachAs"
                    />
                    <form-radio
                        :text="
                            $t('reports.scheduleModal.attachReport.optionXLS')
                        "
                        value="xls"
                        v-model="reportAttachAs"
                    />
                </div>
            </form-control-group-row>

            <hr />

            <form-control-group-row class="py-6">
                <form-toggle
                    class="w-full"
                    v-model="active"
                    :label="$t('reports.scheduleModal.status.label')"
                    :description="
                        active
                            ? $t('reports.scheduleModal.status.optionActive')
                            : $t('reports.scheduleModal.status.optionInactive')
                    "
                    size="sm"
                />
            </form-control-group-row>
        </div>

        <template #modal-footer>
            <div class="flex justify-end gap-3 pt-1">
                <base-button variant="default" @click="closeModal">
                    {{ $t('general.cancel') }}
                </base-button>

                <base-button
                    @click="onSubmit"
                    :loading="loadingGet || loadingSave"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { DATE_SERVER_FORMAT } from '@tenant/utils/constants'
import { dayjsFormat, now, getDate } from '@tenant/utils/day'
import { REPORT_TYPES } from '@tenant/modules/tenant/reports/utils/constants'
import dayjs from 'dayjs'

const { handleSubmit, setErrors } = useForm()

const { t } = useI18n()
const { successNotify, errorNotify } = useNotification()

const emit = defineEmits(['modal-close', 'schedule'])
const { execute: executeSave, loading: loadingSave } = useApi(
    '/api/schedule-reports/{report_type}',
    'POST'
)
const { execute: executeGet, loading: loadingGet } = useApi(
    '/api/schedule-reports/{report_type}',
    'GET'
)

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },

    reportName: {
        type: String,
        default: '',
    },

    reportType: {
        type: String,
        default: '',
        validator(value) {
            return Object.values(REPORT_TYPES).includes(value)
        },
    },
})

const { value: reportFrequency } = useField('reportFrequency')
const { value: reportDate } = useField('reportDate')
const { value: reportHours } = useField('reportHours')
const { value: reportMinutes } = useField('reportMinutes')
const { value: reportRecipients } = useField('reportRecipients')
const { value: reportAttachAs } = useField('reportAttachAs')
const { value: active } = useField('active')

// Set default values
onMounted(() => {
    getScheduleReport()
})

const convertToUTC = (localDate, localHour, localMinute) => {
    const localDateTime = new Date(
        `${localDate}T${localHour}:${localMinute}:00`
    )

    const utcYear = localDateTime.getUTCFullYear()
    const utcMonth = localDateTime.getUTCMonth() + 1
    const utcDay = localDateTime.getUTCDate()
    const utcHour = localDateTime.getUTCHours()
    const utcMinute = localDateTime.getUTCMinutes()

    const utcDateTime = new Date(
        Date.UTC(utcYear, utcMonth - 1, utcDay, utcHour, utcMinute)
    )

    const date = getDate(utcDateTime.toISOString())

    return extractDateTime(date)
}

const convertToLocalTime = (dateString) => {
    const date = dayjs(dateString)

    return extractDateTime(date)
}

const extractDateTime = (date) => {
    const datePart = date.format(DATE_SERVER_FORMAT)

    const hour = date.hour().toString().padStart(2, '0')
    const minute = date.minute().toString().padStart(2, '0')

    return {
        datePart,
        hour,
        minute,
    }
}

const getScheduleReport = async () => {
    try {
        const data = await executeGet({
            params: {
                report_type: props.reportType,
            },
        })

        if (!data) {
            setDefaultValue()

            return
        }

        const { frequency, start_at, recipients, document_type, is_active } =
            data

        reportFrequency.value = frequency
        const { datePart, hour, minute } = convertToLocalTime(start_at)

        reportDate.value = datePart
        reportHours.value = hour
        reportMinutes.value = minute

        reportRecipients.value = recipients
        reportAttachAs.value = document_type
        active.value = is_active
    } catch (errors) {
        errorNotify({
            title: t('general.error'),
        })
    }
}

const setDefaultValue = () => {
    const { user } = useAuth()
    const defaultEmail = user?.value.email

    reportFrequency.value = 'weekly'
    reportDate.value = dayjsFormat(now(), DATE_SERVER_FORMAT)

    reportHours.value = '15'
    reportMinutes.value = '30'
    reportRecipients.value = defaultEmail ? [defaultEmail] : []
    reportAttachAs.value = 'pdf'
    active.value = false
}
// Options
const frequencyOptions = ref([
    {
        value: 'daily',
        label: t('reports.scheduleModal.frequency.optionDaily'),
    },
    {
        value: 'weekly',
        label: t('reports.scheduleModal.frequency.optionWeekly'),
    },
    {
        value: 'monthly',
        label: t('reports.scheduleModal.frequency.optionMonthly'),
    },
    {
        value: 'quarterly',
        label: t('reports.scheduleModal.frequency.optionQuarterly'),
    },
])

const hoursOptions = computed(() => {
    const hours = []

    for (let i = 1; i <= 24; i++) {
        hours.push({
            value: i.toString().padStart(2, '0'),
            label: i.toString().padStart(2, '0'),
        })
    }

    return hours
})

const minutesOptions = ref([
    {
        value: '00',
        label: '00',
    },
    {
        value: '30',
        label: '30',
    },
])

const closeModal = () => {
    emit('modal-close')
}

const onSubmit = handleSubmit(async () => {
    try {
        const { datePart, hour, minute } = convertToUTC(
            reportDate.value,
            reportHours.value,
            reportMinutes.value
        )

        await executeSave({
            params: {
                report_type: props.reportType,
            },
            data: {
                frequency: reportFrequency.value,
                start_at: `${datePart} ${hour}:${minute}:00`,
                recipients: reportRecipients.value,
                document_type: reportAttachAs.value,
                is_active: active.value,
                report_type: props.reportType,
            },
        })

        successNotify({
            title: t('general.success'),
        })

        closeModal()
    } catch ({ errors }) {
        errorNotify({
            title: t('general.error'),
        })

        setErrors(errors)
    }
})
</script>
