<template>
    <reports-header
        :report-name="$t('reports.cashflowStatement.title')"
        @set-dates="setReportDates"
        @set-date-range-key="setDateRangeKey"
        @export="onExport"
        :date-range-key="dateRangeKey"
        :report-type="REPORT_TYPES.CASH_FLOW_STATEMENT"
        :loading-export="loadingExport"
        query-key="balance_sheet_date"
        query-value="filter.generalLedgers.date"
        @print="printContent('report-content')"
        @cancel-export="cancelExport"
    >
        <div id="report-content" class="flex flex-1 flex-col">
            <!-- Report header -->
            <reports-header-section
                :name="company?.company_name"
                :title="$t('reports.cashflowStatement.title')"
                :startDate="startDate"
                :endDate="endDate"
            />

            <div v-if="loadingSearch" class="relative -mt-16 flex-1">
                <div
                    class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
                >
                    <span
                        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                    >
                        <base-loading size="lg" />
                    </span>
                </div>
            </div>

            <!-- Data table -->
            <reports-table v-else>
                <!-- Item -->
                <template v-for="group in hydrateData" :key="group">
                    <div
                        class="flex justify-between gap-24 px-7 py-2 text-sm text-gray-700"
                        :class="{ 'border-b': !has(group, 'children') }"
                    >
                        <div class="font-bold">
                            {{ group.name }}
                        </div>
                        <div v-if="has(group, 'total')">
                            {{ $filters.numberFormat(group.total ?? 0) }}
                        </div>
                    </div>

                    <template v-if="!isNil(group.profit)">
                        <div
                            class="mb-1 flex justify-between gap-4 py-1 pl-16 pr-7 text-sm font-semibold text-primary-700 hover:cursor-pointer hover:text-primary-500 hover:underline"
                        >
                            <span>
                                {{
                                    $t(
                                        'reports.cashflowStatement.profitForTheYear'
                                    )
                                }}
                            </span>
                            <span>
                                {{ $filters.numberFormat(group.profit) }}
                            </span>
                        </div>
                        <span
                            class="flex py-2 pl-14 text-sm font-bold text-gray-700"
                        >
                            {{
                                $t('reports.cashflowStatement.adjustments', {
                                    name: group.profitName,
                                })
                            }}
                        </span>
                    </template>

                    <reports-table-item
                        v-for="item in group.children"
                        :key="item"
                        :name="item.name"
                        :total="item.total"
                        :has-total="false"
                        :class="{ 'pl-8': !isNil(group.profit) }"
                    >
                        <template #itemNameLabel>
                            <div
                                @click="onChildrenClick(item.id)"
                                class="flex justify-between gap-4 pl-16 pr-7 font-semibold text-primary-700 hover:cursor-pointer hover:text-primary-500 hover:underline"
                            >
                                <span>{{ item.name }}</span>
                                <span>
                                    {{ $filters.numberFormat(item.value ?? 0) }}
                                </span>
                            </div>
                        </template>
                    </reports-table-item>

                    <template v-if="group.totalItem">
                        <div
                            v-if="group.profitName"
                            class="mb-2 ml-6 flex justify-between gap-4 border-b pb-2 pl-8 pr-7 pt-3 text-sm font-bold text-gray-700"
                        >
                            <span>
                                {{
                                    $t(
                                        'reports.cashflowStatement.totalAdjustments',
                                        {
                                            name: group.profitName,
                                        }
                                    )
                                }}
                            </span>
                            <span>
                                {{
                                    $filters.numberFormat(
                                        group.totalItem.adjustment
                                    )
                                }}
                            </span>
                        </div>

                        <div
                            class="flex justify-between gap-24 border-b px-7 py-2 text-sm text-gray-700"
                        >
                            <div class="font-semibold">
                                {{ group.totalItem.name }}
                            </div>
                            <div>
                                {{
                                    $filters.numberFormat(
                                        group.totalItem.total ?? 0
                                    )
                                }}
                            </div>
                        </div>
                    </template>
                </template>

                <template #footer>
                    <div
                        class="flex justify-end gap-24 border-b px-7 py-2 text-sm text-gray-700"
                    >
                        <div class="font-bold">
                            {{
                                $t(
                                    'reports.cashflowStatement.netIncreaseInCash'
                                )
                            }}
                        </div>
                        <div class="min-w-24 text-right">
                            {{
                                $filters.numberFormat(
                                    balances?.net_change_in_cash ?? 0
                                )
                            }}
                        </div>
                    </div>

                    <div
                        class="flex justify-end gap-24 border-b px-7 py-2 text-sm text-gray-700"
                    >
                        <div class="font-bold">
                            {{
                                $t(
                                    'reports.cashflowStatement.beginningCashBalance'
                                )
                            }}
                        </div>
                        <div class="min-w-24 text-right">
                            {{
                                $filters.numberFormat(
                                    balances?.beginning_cash_balance ?? 0
                                )
                            }}
                        </div>
                    </div>

                    <div
                        class="flex justify-end gap-24 border-b px-7 py-2 text-sm text-gray-700"
                    >
                        <div class="font-bold">
                            {{
                                $t(
                                    'reports.cashflowStatement.endingCashBalance'
                                )
                            }}
                        </div>
                        <div class="min-w-24 text-right">
                            {{
                                $filters.numberFormat(
                                    balances?.ending_cash_balance ?? 0
                                )
                            }}
                        </div>
                    </div>
                </template>
            </reports-table>
        </div>
    </reports-header>
</template>

<script setup>
import { has, isEmpty, isNil } from 'lodash-es'
import { REPORT_TYPES } from '@tenant/modules/tenant/reports/utils/constants'
import { useReport } from '@tenant/modules/tenant/reports/composables/use-report'
import { printContent } from '@tenant/utils/helper'

const {
    startDate,
    endDate,
    getDateRangeKey,
    setReportDates,
    dateRangeKey,
    setDateRangeKey,
    exportReportTypeWithDocumentType,
    recreateCancelToken,
    loading: loadingExport,
    cancelTokenSource,
} = useReport(REPORT_TYPES.CASH_FLOW_STATEMENT)

const { t } = useI18n()
const route = useRoute()
const { entities: balances, useApiSearch } = useApiFactory(
    'cash-flow-statement'
)
const { execute, loading: loadingSearch } = useApiSearch(
    {
        ...route.query,
    },
    true,
    true
)
const { company } = useAuth()
const router = useRouter()
useHead({ title: t('reports.cashflowStatement.title') })

const onChildrenClick = (id) => {
    router.push({
        name: 'reports.account-transactions',
        query: {
            range_key: getDateRangeKey(),
            chart_of_account_id: id,
        },
    })
}

watch(
    () => route?.query,
    () => {
        if (
            route?.query?.filter?.['generalLedgers.date'] &&
            route?.query?.filter?.['generalLedgers.date'].length > 1 &&
            route?.query?.filter?.['generalLedgers.date'][1]?.length > 1
        ) {
            router.replace({
                query: {
                    ...route?.query,
                    from_date: route.query.filter['generalLedgers.date'][1][0],
                    to_date: route.query.filter['generalLedgers.date'][1][1],
                },
            })

            return execute({
                ...route?.query,
                from_date: route.query.filter['generalLedgers.date'][1][0],
                to_date: route.query.filter['generalLedgers.date'][1][1],
            })
        }

        return execute({
            ...route?.query,
        })
    },
    { immediate: !isEmpty(route?.query.filter), flush: 'post' }
)

const hydrateData = computed(() => {
    return [
        {
            name: t('reports.cashflowStatement.cashFlowOperating'),
            children: mapChildren(balances.value?.operating_activities),
            profit: balances.value?.net_income_operating_activities ?? 0,
            profitName: t('reports.cashflowStatement.operatingProfit'),
            totalItem: {
                name: t('reports.cashflowStatement.netCashOperating'),
                total: balances.value?.net_cash_provided_operating ?? 0,
                adjustment:
                    balances.value?.operating_activities_adjustment ?? 0,
            },
        },
        {
            name: t('reports.cashflowStatement.cashFlowInvesting'),
            children: mapChildren(balances.value?.investing_activities),
            totalItem: {
                name: t('reports.cashflowStatement.netCashInvesting'),
                total: balances.value?.net_cash_provided_investing ?? 0,
            },
        },
        {
            name: t('reports.cashflowStatement.cashFlowFinancing'),
            children: mapChildren(balances.value?.financing_activities),
            totalItem: {
                name: t('reports.cashflowStatement.netCashFinancing'),
                total: balances.value?.net_cash_provided_financing ?? 0,
            },
        },
        {
            name: t('reports.cashflowStatement.cashFlowCash'),
            children: mapChildren(balances.value?.cash_activities),
            totalItem: {
                name: t('reports.cashflowStatement.netCashCash'),
                total: balances.value?.net_cash_provided_cash ?? 0,
            },
        },
    ]
})

const mapChildren = (data) => {
    return data?.map((item) => ({
        id: item.id,
        name: item.name,
        value: item.total,
    }))
}

const onExport = async (type) => {
    await exportReportTypeWithDocumentType(
        type,
        REPORT_TYPES.CASH_FLOW_STATEMENT,
        route.query
    )
}

const cancelExport = () => {
    cancelTokenSource.cancel()
    recreateCancelToken()
}
</script>
