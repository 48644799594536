import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'reports',
        path: '/reports',
        redirect: {
            name: 'reports.profit-and-loss',
        },
        meta: {
            closeSidebar: false,
        },
        children: [
            {
                name: 'reports.profit-and-loss',
                path: 'profit-and-loss',
                component: () => import('./pages/reports-profit-and-loss.vue'),
                meta: {
                    closeSidebar: false,
                },
            },
            {
                name: 'reports.balance-sheet',
                path: 'balance-sheet',
                component: () => import('./pages/reports-balance-sheet.vue'),
                meta: {
                    closeSidebar: false,
                },
            },
            {
                name: 'reports.cashflow-statement',
                path: 'cashflow-statement',
                component: () =>
                    import('./pages/reports-cashflow-statement.vue'),
                meta: {
                    closeSidebar: false,
                },
            },
            {
                name: 'reports.account-transactions',
                path: 'account-transactions',
                component: () =>
                    import('./pages/reports-account-transactions.vue'),
                meta: {
                    closeSidebar: false,
                },
            },
            {
                name: 'reports.ledger',
                path: 'general-ledger',
                component: () => import('./pages/reports-general-ledger.vue'),
                meta: {
                    closeSidebar: false,
                },
            },
            {
                name: 'reports.detail-ledger',
                path: 'reports-detail-ledger',
                component: () =>
                    import('./pages/reports-detail-general-ledger.vue'),
                meta: {
                    closeSidebar: false,
                },
            },
            {
                name: 'reports.trial-balance',
                path: 'trial-balance',
                component: () => import('./pages/reports-trial-balance.vue'),
                meta: {
                    closeSidebar: false,
                },
            },
        ],
    },
]

export default {
    routes,
    meta: {
        layout: 'dashboard-layout',
        guard: GUARD.TENANT,
        permission: 'view_reports',
    },
}
